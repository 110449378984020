// fetchData.js
import axios from 'axios';
import { useAppContext } from '../AppContextProvider';

export const fetchData = async (selectedAdress) => {

    const savedLocations = JSON.parse(localStorage.getItem('savedLocations'));
    const selectedLocation = savedLocations?.find((location) => location.check);
    try {
        const token = localStorage.token;
        const lati = token ? selectedAdress?.coordinates?.lat : selectedLocation?.lat;
        const long = token ? selectedAdress?.coordinates?.lng : selectedLocation?.lng;

        const response = await axios.get(`https://api.vapedelivery.com/api/main/avail-store?lat=${lati}&lng=${long}`, {
            headers: {
                'Authorization': `Bearer ${token}` // Set the token in the Authorization header
            },
            withCredentials: false,
        });
        return response.data;

    } catch (error) {
        // navigate('/product');
        return {};

    }
};



export const fetchUserData = async (setUserData, setSelectedAdress) => {
    const token = localStorage.token;
    let activeAddress;

    try {
        if (!token) {
            console.error('No token found in localStorage');
            return;
        }
        const response = await axios.get('https://api.vapedelivery.com/api/main/profile', {
            headers: { 'Authorization': `Bearer ${token}` },
            withCredentials: false,
        });
        setUserData(response.data);


        activeAddress = response.data?.addresses?.find(address => address.active === true);
        if (token) {
            fetchStoreData(activeAddress, setSelectedAdress)
        }

        setSelectedAdress(activeAddress); // Update address state once user data is fetched
    } catch (error) {
        console.error('Error fetching user data:', error);
    }
};

export const fetchStoreData = async (selectedAdress, setSelectedAdress) => {
    const token = localStorage.token;
    const savedLocations = JSON.parse(localStorage.getItem('savedLocations'));
    const selectedLocation = savedLocations?.find((location) => location.active);

    try {
        const lati = token ? selectedAdress?.coordinates?.lat : selectedLocation?.lat;
        const long = token ? selectedAdress?.coordinates?.lng : selectedLocation?.lng;

        const response = await axios.get(`https://api.vapedelivery.com/api/main/avail-store?lat=${lati}&lng=${long}`, {
            headers: { 'Authorization': `Bearer ${token}` },
            withCredentials: false,
        });

        setSelectedAdress(prevFormData => ({
            ...prevFormData,
            ...response.data,
        }));

    } catch (error) {

        setSelectedAdress(prevFormData => ({
            ...prevFormData,
            ...error.response.data,
        }));
        // navigate('/product');


    }

};


export const capitalizeName = (name) => {
    if (!name) return '';
    return name.split(' ').map(word => {
        return word?.charAt(0).toUpperCase() + word?.slice(1).toLowerCase();
    }).join(' ');
}