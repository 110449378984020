import React, { Component, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppContext } from '../AppContextProvider';
import Swal from 'sweetalert2';
import CardImages from '../Assets/card.png'
import cardtype from '../Assets/cardtypeIcon.png'
import cardgif from '../Assets/cardd.gif'
import cardpic from '../Assets/credit-card.png'
import cashgif from '../Assets/cash.gif'
import boxgif from '../Assets/box.png'
import { RotatingLines } from 'react-loader-spinner'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import ReCAPTCHA from "react-google-recaptcha";
import axios from 'axios';
const Payment = ({ payment, data, setshowPaymentMod, total, totalQuantity }, props) => {

  const [formData, setFormData] = useState(null);
  const [loader, setLoader] = useState(false);
  const { value, setValue } = useAppContext();
  const [isFocused, setIsFocused] = useState(false);
  const [verfied, setVerifed] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState(0);

  let navigate = useNavigate();
  useEffect(() => {
    setFormData(data)
  })

  useEffect(() => {
    if (paymentMethod === 1) {
      const elements = window.clover.elements();
      const displayError = document.getElementById('card-errors');
      const cardNumberElement = elements.create('CARD_NUMBER', styles);
      const cardDateElement = elements.create('CARD_DATE', styles);
      const cardCvvElement = elements.create('CARD_CVV', styles);
      const cardPostalCodeElement = elements.create('CARD_POSTAL_CODE', styles);
      cardNumberElement.mount('#card-number');
      cardDateElement.mount('#card-date');
      cardCvvElement.mount('#card-cvv');
      cardPostalCodeElement.mount('#card-postal-code');
      cardNumberElement.addEventListener('change', (event) => {
        displayError.textContent = event.error ? event.error.message : '';
      });
      cardDateElement.addEventListener('change', (event) => {
        displayError.textContent = event.error ? event.error.message : '';
      });
      cardCvvElement.addEventListener('change', (event) => {
        displayError.textContent = event.error ? event.error.message : '';
      });
      cardPostalCodeElement.addEventListener('change', (event) => {
        displayError.textContent = event.error ? event.error.message : '';
      });
      const cloverFooter = document.getElementsByClassName('clover-footer')[0];
      if (cloverFooter) {
        cloverFooter.style.display = "none";
      }
      return () => {
        cloverFooter && cloverFooter.parentNode.removeChild(cloverFooter);
      };
    }
  }, [paymentMethod]);

  const callCreateChargeAPI = async (response) => {
    const displayError = document.getElementById('card-errors');
    if (!data) {
      displayError.textContent = 'Item not added to cart added again';
      return;
    } else {
      // displayError.textContent = result.token;
      const updatedData = {
        ...formData,
        source: response.token,
        customer: localStorage.userid
      };
      try {
        const chargeResponse = await fetch('https://api.vapedelivery.com/api/main/createCharge'
          , {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(updatedData),
            mode: 'cors',
          });
        const serverdata = await chargeResponse.json();
        setLoader(false);
        if (chargeResponse.status === 201) {
          // Swal.fire({
          //   icon: 'success',
          //   title: data.message,
          //   showConfirmButton: true,
          // });
          setValue([])
          navigate('/paymentSucess', { state: { payment: payment } });
        } else {

          Swal.fire({
            icon: 'warning',
            title: serverdata.error,
            showConfirmButton: true,
          });
        }
        // props.outputHandler(`Payment Success, Confirmation # is - ${chargeResponse.data.id}`);
      } catch (error) {
        setLoader(false);
        // console.error('Error:', error);
        Swal.fire({
          icon: 'warning',
          title: 'An error occurred. Please try again.',
          showConfirmButton: true,
        });
      }
      // displayError.textContent = chargeResponse.data;
      // return chargeResponse.data;
    }
  };


  const onChangeCAP = (event) => {
    setVerifed(true);
  }
  const handleFormSubmit = async (event) => {
    event.preventDefault();

    const displayError = document.getElementById('card-errors');
    setLoader(true)
    try {
      const result = await window.clover.createToken();

      if (result.errors) {
        setLoader(false)

        Object.values(result.errors).forEach(value => {
          //  displayError.textContent = value;
          Swal.fire({
            icon: 'warning',
            title: `Car`,
            showConfirmButton: true,
          });
        });
      } else {
        await callCreateChargeAPI(result);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const cashhandler = async (response) => {
    const updatedData = {
      ...formData,
      customer: localStorage.userid,
    };
    if (updatedData.source) {
      delete updatedData.source;
    }

    try {
      const chargeResponse = await fetch('https://api.vapedelivery.com/api/main/place-order'
        , {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(updatedData),
          mode: 'cors',
        });
      const serverdata = await chargeResponse.json();
      setLoader(false);
      if (chargeResponse.status === 201) {
        setValue([])
        navigate('/paymentSucess', { state: { payment: payment } });
      } else {
        Swal.fire({
          icon: 'warning',
          title: serverdata.error,
          showConfirmButton: true,
        });
      }
    } catch (error) {
      setLoader(false);
      Swal.fire({
        icon: 'warning',
        title: 'An error occurred. Please try again.',
        showConfirmButton: true,
      });
    }
  };

  const styles = {
    body: {
      fontFamily: 'Roboto, Open Sans, sans-serif',
      fontSize: '16px',
    },
    iframe: {
      height: '40px'
    },
    hydrated: {

    },
    input: {
      fontSize: '13px',
      border: '1px solid #aaa',
      height: '50px',
      borderRadius: '50px',
      padding: '8px'
    },
    img: {
      right: '10px !important',
      top: '10px !important',
    }
  };
  const [isHovered, setIsHovered] = useState(false);
  const [isHovered2, setIsHovered2] = useState(false);

  return (
    <div className='flex w-full text-start max-w-screen-xl mx-auto justify-around items-start h-full my-4  '>
      <div className='md:flex w-full xl:w-2/3 justify-between  p-2'>
        <div className=" w-full md:w-[55%] rounded p-4   w-full md:h-[90%] bg-white" >
          <div id="card-errors" role="alert" />
          <p className='font-bold  text-xl text-gray-600 '>Select Payment method</p>
          <div className='flex w-3/4 gap-x-3 py-3 mb-3'>
            <button

              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
              onClick={() => setPaymentMethod(1)}
              className={`block w-1/2 ${paymentMethod == 1 ? 'bg-white border-orange-500' : "bg-[#EAE9E9]"}  shadow  border focus: rounded rounded-xl px-3 md:py-3 py-1 font-semibold `}>
              <img className='md:h-[71px] h-[40px]' src={isHovered ? cardgif : cardpic} alt="Cash GIF" />
              <label className="text-[9px] md:text-[16px] xl:text-[16px] text-gray-600">Credit/ Debit</label>
            </button>
            <button
              onMouseEnter={() => setIsHovered2(true)}
              onMouseLeave={() => setIsHovered2(false)}
              onClick={() => setPaymentMethod(2)}
              className={`block w-1/2  bg-[#EAE9E9] shadow ${paymentMethod == 2 ? 'bg-white border-orange-500' : "bg-[#EAE9E9]"} border  rounded rounded-xl px-3  md:py-3 py-1 font-semibold `}            >
              <img className={`${isHovered2 ? 'md:h-[71px] h-[40px]' : 'md:h-[70px] h-[40px]'}`} src={isHovered2 ? cashgif : boxgif} alt="Cash GIF" />
              <label className="text-start text-gray-600 text-[9px] md:text-[16px] ">Cash on Delivery</label>
            </button>
          </div>
          {paymentMethod > 0 && <div className=" justify-center shadow border px-4 p-3 rounded rounded-xl ">
            {paymentMethod === 1 && <>
              <img className='md:h-[40px] h-[25px] self-center' src={cardtype} alt="Cash GIF" />
              <form id="payment-form" noValidate autoComplete="on" >
                <fieldset className="FormGroup pt-4">
                  <div className="FormRow ">
                  </div>
                  <div className="FormRow w-full flex flex-col justify-center items-center ">
                    <div style={{ height: '60px', width: '96%' }} id="card-number" className="field card-number rounded-full mb-2" />
                    <div style={{ height: '60px', width: '96%' }} id="card-date" className="field third-width mb-2" />
                    <div style={{ height: '60px', width: '96%' }} id="card-cvv" className="field third-width mb-2" />
                    <div style={{ height: '60px', width: '96%' }} id="card-postal-code" className="field third-width mb-2" />
                  </div>
                </fieldset>
              </form>
            </>}
            {paymentMethod === 2 && <div className=' justify-between w-full gap-x-3 py-2'>
              <p className='text-start text-[10px] text-gray-500 py-2 font-medium'>You may pay in cash to our courier upon receiving your parcel at the doorstep</p>
              <p className='text-start text-[10px] text-gray-500 py-2 font-medium'>Before agreeing to receive the parcel, check if your delivery status has been updated to 'Out for Delivery'</p>

              <p className='text-start text-[10px] text-gray-500 py-2 font-medium'>
                Cash on Delivery is free. There is no extra fee when using other payment methods</p>
            </div>}
            <div className='flex w-full flex-col justify-center  gap-2'>
              <RotatingLines
                visible={loader}
                height="40"
                width="40"
                color="orange"
                strokeColor='orange'
                strokeWidth="5"
                animationDuration="0.75"
                ariaLabel="rotating-lines-loading"
                wrapperStyle={{}}
                wrapperClass=""
              />

              <div className="w-full " style={{ transform: 'scale(0.8)', transformOrigin: '0 0' }}>
                <ReCAPTCHA
                  sitekey="6LdrXCMqAAAAAP0zXpxCH0WuU4W4Ed7YGImclnPr"
                  onChange={onChangeCAP}
                />
              </div>
              <div className='flex justify-around gap-2'>
                {paymentMethod === 1 && (!loader) && verfied && <button disabled={!verfied} className={`block w-1/2 max-w-xs  bg-orange-500 hover:bg-orange-700 focus:bg-orange-700 text-white rounded-full px-6 py-2 font-semibold`} variant="contained" color="primary" size="large" onClick={handleFormSubmit}>
                  PAY NOW${total}
                </button>}
                {paymentMethod === 2 &&
                  (!loader) && verfied && <button disabled={!verfied} className={`block w-full max-w-xs  bg-orange-500 hover:bg-orange-700 focus:bg-orange-700 text-white rounded-full px-6 py-2 font-semibold`} variant="contained" color="primary" size="large" onClick={cashhandler}>
                    CONFIRM PURCHASE
                  </button>}
                <button

                  className='flex w-1/2 rounded-full cursor-pointer justify-center shadow  border border-gray-500 text-xs font-bold py-3 px-6 leading-none flex items-center text-center text-gray-500 hover:bg-gray-400 hover:text-white' type="button" variant="contained" size="large" onClick={() => setshowPaymentMod(true)}>
                  CANCEL
                </button>
              </div>
            </div >
          </div >}
        </div >
        <div className="w-full md:w-[40%] justify-center p-4 border bg-white mt-4 rounded rounded-xl w-full md:h-[90%] ">
          <p className='font-bold  text-xl text-gray-600 '>Order Summary</p>
          < div className='flex justify-between w-full gap-x-3 py-1'>
            <p className='text-start text-[10px] text-gray-500 py-2 font-medium'>Subtotal ( 1 item and shopping fee included)</p>
            <p className='text-start text-[10px] text-gray-500 py-2 font-medium'>{total}</p>
          </div>
          <div className='flex py-2 justify-beteen my-2 w-full'>
            <label className=' w-1/2 text-black'><b>Order Total:</b></label>
            <label className=" text-end w-1/2 text-orange-500"><b>${total}</b></label>
          </div>
        </div>
        {/* <img src={CardImages} alt="Card" width={400} height={300} /> */}

      </div>
    </div >
  );
}

const Checkout = ({ mapData }) => {

  let navigate = useNavigate();
  const { userData, setUserData } = useAppContext();
  const [showPaymentMod, setshowPaymentMod] = useState(true)
  const [taxpercentage, setTaxPercentage] = useState(true)
  const [promocodedis, setPromodis] = useState('');
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const [formData, setFormData] = useState({
    // fullname: '',
    // email: '',
    // phone: '',
    // street: '',
    // city: '',
    // state: '',

    source: 'clv_1TSTSorzbVGx6uMAA5s2d2y1',
    cart: [], // Initialize as an empty array
  });
  const { selectedAdress, setSelectedAdress } = useAppContext()
  const location = useLocation();
  const { value, setValue } = useAppContext();
  // --------------------
  const [tip, setTip] = useState(0);
  const [customTip, setCustomTip] = useState('');
  const [showCustomTip, setShowCustomTip] = useState(false);
  const [selectedAmount, setSelectedAmount] = useState(0);
  // totalOrders ---------------
  const totalQuantity = location.state?.totalQuantity;
  const totalOrders = userData?.orders?.length + 1 || 0;

  let message = "";

  // Calculate discount based on milestones
  // const tenOrderdiscount = (totalOrders >= 10 && totalQuantity >= 10) ? 25 : 0;  
  const progress = Math.min((totalOrders % 10) * 10, 100); // Shows 100% for each set of 10 orders
  // Function to add preset tip amounts
  const addTip = (e) => {
    setTip(e);
  };

  const handleCustomTipClick = () => {
    if (showCustomTip && customTip) {
      const customAmount = parseFloat(customTip);
      if (!isNaN(customAmount) && customAmount > 0) {
        setTip(customAmount);
      }
      setCustomTip(''); // Clear input after adding
    }
    setShowCustomTip(!showCustomTip); // Toggle input visibility
  };
  // -----------------------------
  const handleTipClick = (amount) => {
    setTip(amount);
    setCustomTip(''); // Clear custom tip when a standard tip is selected
    setShowCustomTip(false); // Hide custom tip input
  };

  const toggleCustomTip = () => {

    if (showCustomTip && customTip) {
      const customAmount = parseFloat(customTip);
      if (customAmount > 0) {
        setTip(customAmount);
      }
      setCustomTip(''); // Clear input
    } else {
      setTip(tip); // Clear standard tip when showing custom tip
    }
    setShowCustomTip((prev) => !prev);
  };

  const standardTips = [2, 4, 5, 6];


  // -----------------------------

  const calculateTotal = () => {
    const subtotal = value.reduce((total, item) => total + (item.product.rprice * item.quantity), 0);
    const states = [
      { state: "Pennsylvania", tax: 6.0 },
      { state: "Texas", tax: 6.25 },
      // Add more states as needed
    ];
    const selectedState = selectedAdress?.state || "";
    const stateTaxRate = states.find((s) => s.state === selectedState)?.tax ?? 0;
    const tax = stateTaxRate / 100 * subtotal;
    const deliveryCharge = 4.99;
    let total;
    let discount = 0;
    let firstdiscount = 0;
    const isFirstTimeUser = userData?.orders?.length === 0;
    // --------------
    let referredDis = isFirstTimeUser ? 5 : 0;//1111
    let promodiscount = promocodedis || 0;
    // -----------------
    if (selectedAmount > subtotal) {
      message = "Add more items to apply wallet Amount.";
    }

    const isOrderAbove60 = subtotal > 50;

    if (isFirstTimeUser) {
      firstdiscount += 0.3 * (subtotal); // 50% discount for first-time users
      if (isOrderAbove60 && (totalOrders >= 1)) {
        discount += 0.15 * (subtotal); // Additional 15% discount if order is above $60
      }
    } else if (isOrderAbove60 && (totalOrders >= 1)) {
      discount += 0.15 * (subtotal + tax); // 15% discount if order is above $60 for non-first-time users
    }
    if (isFirstTimeUser || isOrderAbove60) {
      const dicountPrice = ((subtotal - (discount + firstdiscount + promodiscount + referredDis)))
      total = dicountPrice + (deliveryCharge + tax + tip - (message ? 0 : selectedAmount));
    } else {
      total = ((subtotal - (promodiscount + referredDis)) + tax + tip - (message ? 0 : selectedAmount)) + deliveryCharge;
    }

    return {
      subtotal: subtotal.toFixed(2),
      tax: tax.toFixed(2),
      promodiscount: promodiscount,
      wallet: selectedAmount,
      deliveryCharge: deliveryCharge.toFixed(2),
      ...(discount > 0 && { discount: discount.toFixed(2) }), // Add discount only if applicable
      ...(firstdiscount > 0 && { firstdiscount: firstdiscount.toFixed(2) }),
      total: total.toFixed(2)
    };
  };
  const totalDetails = calculateTotal(); // Calculate total, tax, and delivery charge once

  useEffect(() => {
    const { total, tax, deliveryCharge, discount, promodiscount, referredDis } = calculateTotal();
    const products = value.map(item => ({
      productId: item.product._id,
      flavour: item.product.flavours,
      quantity: item.quantity,
    }));

    setFormData(prevFormData => ({
      ...prevFormData,
      cart: products,    // Update cart with the new products
      total: parseFloat(total),
      tax: parseFloat(tax),
      discount: discount ? parseFloat(discount) : 0,
      devcharges: parseFloat(deliveryCharge),

    }));
  }, [])



  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handlePhoneChange = (value) => {
    setFormData({
      ...formData,
      phone: value
    });
  };
  const handleStateChange = (e) => {
    const stateObj = JSON.parse(e.target.value);
    const { name, tax } = stateObj;

    const statname = "state"
    setTaxPercentage(tax)
    setFormData({
      ...formData,
      [statname]: name,
    });

  };

  const paynow = (cartItems) => {
    // if (formData.fullname) {
    //   document.getElementById("fullname").style.borderColor = "silver";
    // } else {
    //   // document.getElementById("fullname").style.borderColor = "red";
    // }

    // if (formData.email) {
    //   document.getElementById("email").style.borderColor = "silver";
    // } else {
    //   document.getElementById("email").style.borderColor = "red";
    // }

    // if (!formData.phone) {
    //   document.getElementById("phone").style.borderColor = "red";
    // } else {
    //   document.getElementById("phone").style.borderColor = "silver";
    // }
    // if (!formData.street) {
    //   document.getElementById("street").style.borderColor = "red";
    // } else {
    //   document.getElementById("street").style.borderColor = "silver";
    // }

    // if (!formData.state) {
    //   document.getElementById("state").style.borderColor = "red";
    // } else {
    //   document.getElementById("state").style.borderColor = "silver";
    // }
    // if (!formData.city) {
    //   document.getElementById("city").style.borderColor = "red";
    // } else {
    //   document.getElementById("city").style.borderColor = "silver";
    // }
    // if (formData.zipcode) {
    //   document.getElementById("zipcode").style.borderColor = "silver";
    // } else {
    //   document.getElementById("zipcode").style.borderColor = "red";
    // }

    const { total, tax, deliveryCharge, discount, firstdiscount, promodiscount, wallet } = calculateTotal();
    // Extract product data from cartItems array
    const products = value.map(item => ({
      productId: item.product._id,
      flavour: item.product.flavours,
      quantity: item.quantity,
    }));

    // const business = storeproduct?.frmData?.business ? storeproduct.frmData.business + ', ' : '';
    // const building = storeproduct?.frmData?.location ? storeproduct.frmData.location + ', ' : '';
    // Update formData state with new data
    setFormData(prevFormData => ({
      ...prevFormData,
      cart: products, // Update cart with the new products
      fullname: userData?.profile?.name || formData.fullname,
      phone: userData?.profile?.phone || formData.phone || "-",
      email: userData?.profile?.email || formData.email,
      street: selectedAdress?.street,
      state: selectedAdress?.state,
      state: selectedAdress?.label,
      city: selectedAdress.city || "-",
      zipcode: selectedAdress.zipcode || "1234",
      tax: parseFloat(tax),
      promodiscount: promodiscount,
      devcharges: parseFloat(deliveryCharge),
      discount: discount ? parseFloat(discount) : 0,
      firstdiscount: firstdiscount ? parseFloat(firstdiscount) : 0,
      total: parseFloat(total),
      tip: tip,
      wallet: selectedAmount,

      // referred: '6661f5d7d743446c0d651b64'
      referred: userData?.profile?.referred
    }));

    if (formData.fullname
      && formData.email
      // && formData.phone
      // && formData.street
      // && formData.state
      // && formData.city
      // && formData.zipcode
    ) {
      // Make sure cart is updated here

    }
    setshowPaymentMod(false)
    // Now you can send formData to your API
  };

  const [isChecked, setIsChecked] = useState(false);
  const handleCheckboxChange = () => {
    if (selectedAdress?.storeAddress && userData?.profile?.phone && totalQuantity > 0) {
      setIsChecked(!isChecked);
    }
  };
  const [isFocused, setIsFocused] = useState(false);
  const inputStyle = {
    width: '100%',
    // padding: '0.75rem',
    border: '1px solid #E5E7EB', // border-gray-200
    outline: 'none',
    paddingTop: '20px ',
    paddingBottom: '20px ',
    transition: 'border-color 0.2s ease-in-out' // Optional: smooth transition for border color change
  };
  const capitalizeName = (name) => {
    if (!name) return '';

    return name.split(' ').map(word => {
      return word.charAt(0).toUpperCase() + word?.slice(1).toLowerCase();
    }).join(' ');
  }
  // const formattedName = capitalizeName(userData?.profile?.name);

  const focusStyle = isFocused ? { borderColor: '#FB923C' } : {};
  const [promocode, setPromo] = useState('');

  const inputPromo = (e) => {
    const { name, value } = e.target
    setPromo({
      ...promocode,
      [name]: value,
    })
  }

  const applyPromo = () => {
    const token = localStorage.token;
    const totalAmount = Math.floor(Number(totalDetails.total))

    const data = {
      ...promocode,
      amount: totalAmount
    }

    axios.post('https://api.vapedelivery.com/api/main/validate-promo', data, {
      headers: {
        'Authorization': `Bearer ${token}`
      },
      withCredentials: false
    }).then(response => {
      // navigate('/product?userid=' + response.data.UserId);
      Swal.fire({
        icon: 'sucess',
        title: `PromoCode Applied Successful`,
        showConfirmButton: true,
      });

      const discountValue = parseFloat(response.data.discount.replace(/[^\d.-]/g, ''));
      setPromodis(discountValue)
    }).catch(error => {
      console.error('Error fetching data:', error);
      Swal.fire({
        icon: 'sucess',
        title: `PromoCode ${error}`,
        showConfirmButton: true,
      });
    });
  }


  // const handleSliderChange = (e) => {
  //   const { name, value } = e.target;

  //   setFormData({
  //     ...formData,
  //     [name]: Number(value),
  //   });
  // };

  const handleSliderChange = (e) => {
    setSelectedAmount(Number(e.target.value));
  };

  return (
    <div className="checkout w-full bg-white">
      {showPaymentMod ?
        <div className='flex justify-center items-center h-full bg-white'>
          <div className={`md:w-[80%] xl:flex gap-x-6 text-gray-700 h-full inline-block align-bottom bg- rounded-lg text-left overflow-hidden  sm:my-8 sm:align-middle `}>
            <div className="xl:w-2/3  flex flex-col  justify- ">
              <p className='text-gray-500 font-semibold  text-lg p-2'>Delivery address</p>
              <div className="w-full mb-2  flex flex-col border-b justify-center xl:px-5 pb-2 pt-4 bg-white">
                {userData.profile ?
                  <div className='w-full rounded-xl px-2 bg-white  text-left'>
                    <p className=' text-2xl text-gray-600 font-bold text-md'>{(location.type !== 'Office') || (location.type !== 'Home') && 'Other2'}</p>
                    <p className=' text-2xl text-gray-600 font-bold text-md'>{selectedAdress.type === 'Home' && selectedAdress?.type}</p>
                    <p className=' text-2xl text-gray-600 font-bold text-md'>{selectedAdress.type === 'Office' && selectedAdress?.type}</p>
                    <p className='  text-[#757575] font-medium text-md my-2'>{selectedAdress?.label ? selectedAdress.label + ', ' : ''} {selectedAdress?.street ? selectedAdress.street + ', ' : ''} {selectedAdress?.city ? selectedAdress.city + ', ' : ''}{selectedAdress?.state} </p>
                    <p className='  text-[#757575] font-medium text-md my-2'>{userData?.profile?.phone}</p>
                    <p className='  text-[#757575] font-medium text-md my-2'>{userData?.profile?.email}</p>
                  </div>
                  :
                  <div className="w-full mx-auto rounded-lg   px-5 " >
                    <h1 className="mb-3 font-bold text-lg uppercase">1) Personal Information</h1>
                    <div className='md:flex gap-2 w-full'>
                      <div className="mb-3 w-full">
                        <label htmlFor="fullname" className="font-bold text-sm mb-2 ml-1">Full Name*</label>
                        <input id="fullname" name="fullname" value={formData.fullname} onChange={(e) => handleChange(e)} className="w-full px-3 py-2 mb-1 border-2 border-gray-200 rounded-md focus:outline-none focus:border-orange-500 transition-colors" placeholder="John Smith" type="text" />
                      </div>

                    </div>
                    <div className='md:flex gap-2 w-full'>
                      <div className="mb-3 md:w-1/2">
                        <label htmlFor="email" className="font-bold text-sm mb-2 ml-1">Email address *</label>
                        <input id="email" name="email" value={formData.email} onChange={(e) => handleChange(e)} className="w-full px-3 py-2 mb-1 border-2 border-gray-200 rounded-md focus:outline-none focus:border-orange-500 transition-colors" placeholder="example@example.com" type="email" />
                      </div>
                      <div className="mb-3 md:w-1/2">
                        <label htmlFor="phone" className="font-bold text-sm mb-2 ml-1">Phone *</label>
                        {/* <input id="phone" name="phone" value={formData.phone} onChange={(e) => handleChange(e)} className="w-full px-3 py-2 mb-1 border-2 border-gray-200 rounded-md focus:outline-none focus:border-orange-500 transition-colors" placeholder="(555) 555-1234" type="number" /> */}

                        <PhoneInput
                          placeholder="Enter phone number"
                          country={'us'}
                          inputStyle={{ ...inputStyle, ...focusStyle }}
                          onlyCountries={['us']}
                          value={formData.phone}
                          onChange={handlePhoneChange}
                          className="w-full  border-2 border-gray-200 rounded-md focus:outline-none focus:border-orange-500 transition-colors"
                        /> </div>
                    </div>

                  </div>}
              </div>
              {/* Bill info */}
              <h1 className="text-gray-500 font-semibold  text-lg px-2">Product Item</h1>
              <div className="w-full  scrollbar mt-2 overflow-y-auto  h-[50vh]  xl:px-5 flex flex-col bg-white rounded-xl pb-1 ">
                {value?.map((item, index) =>
                  <div key={index} className='  justify-beteen w-full p-3 mb-1 rounded'>
                    <div className='md:flex gap-4 items-center'>
                      <div className='w- '>
                        <img className="w-20 h-20 rounded  " src={`https://api.vapedelivery.com/${item.product.featured}`} alt="" />
                        {/* <img className="w-28 h-28 rounded " src={`http://ec2-3-17-166-251.us-east-2.compute.amazonaws.com${item.product.featured}`} alt="" /> */}
                      </div>
                      <div className='md:w-2/3 pl-2'>
                        <div className='flex justify-between text-start w-full'>
                          <label className=" "><label className="text-md bg-gray-200 px-2">{item.quantity}</label><b>- {item.product.name}</b></label>
                          <label className=' text-gray-600 '><b>${(item.product.rprice * item.quantity).toFixed(2)}</b></label>
                        </div>
                        <p className=' pt-1 text-gray-400 text-sm font-normal'>Flavour: <span className=' font-medium text-[#4D5364]'>{item.product.flavours}</span></p>
                        {/* <p className='pb-1 text-gray-400 text-sm font-semibold'>{item.quantity} <span className=' font-medium text-gray-600'>- items</span></p> */}
                      </div>
                    </div>
                  </div>
                )}

              </div>
              {/* <div className="w-full  flex flex-col items-center justify-center px-5 pb-10 pt-4">
                <div className="w-full mx-auto rounded-lg bg-white shadow-lg p-5 " >
                  <h1 className=" mb-3 font-bold text-lg uppercase">2) shipping details</h1>
                  <div className='md:flex gap-2 w-full'>
                    <div className="mb-3 md:w-1/2">
                      <label htmlFor="street" className="font-bold text-sm mb-2 ml-1">Street</label>
                      <input id="street" name="street" value={formData.street || formData.street} onChange={(e) => handleChange(e)} className="w-full px-3 py-2 mb-1 border-2 border-gray-200 rounded-md focus:outline-none focus:border-orange-500 transition-colors" placeholder="Street" type="text" />
                    </div>
                    <div className="mb-3 md:w-1/2">
                      <label htmlFor="city" className="font-bold text-sm mb-2 ml-1">City *</label>
                      <input id="city" name="city" value={formData.city} onChange={(e) => handleChange(e)} className="w-full px-3 py-2 mb-1 border-2 border-gray-200 rounded-md focus:outline-none focus:border-orange-500 transition-colors" placeholder=" City" type="text" />
                    </div>
                  </div>
                  <div className='md:flex gap-2 w-full'>

                    <div className="mb-3 md:w-1/2">
                      <label htmlFor="countries" className="font-bold text-sm mb-2 ml-1">State *</label>
                      <select
                        onChange={handleStateChange}
                        id="state"
                        name="state"
                        className="form-select w-full px-3 py-2 mb-1 border-2 border-gray-200 rounded-md focus:outline-none focus:border-orange-500 transition-colors cursor-pointer"
                      >
                        <option value="" disabled selected>Select a state</option>
                        {states.map((state, index) => (
                          <option key={index} value={JSON.stringify(state)}>{state.name}</option>
                        ))}
                      </select>
                    </div>
                    <div className="mb-3 md:w-1/2">
                      <label htmlFor="zipcode" className="font-bold text-sm mb-2 ml-1">Enter PromoCode</label>
                      <input id="zipcode" name="zipcode" value={formData.zipcode} onChange={(e) => handleChange(e)} className="w-full px-3 py-2 mb-1 border-2 border-gray-200 rounded-md focus:outline-none focus:border-orange-500 transition-colors" placeholder="00000" type="number" />
                    </div>
                  </div>
                </div>
              </div> */}

            </div>
            <div className={`xl:w-1/3  `}>
              <p className='text-gray-500 font-semibold  text-lg p-2'>Order Summary</p>
              <div className={`w-full bg-white px-4 rounded-xl  pb-4 sm:p-6 sm:pb-4 `}>
                <div className='flex  justify-beteen my-2 w-full'>
                  <label className=' w-1/2 text-gray-500'><b>Subtotal:</b></label>
                  <label className=" text-end w-1/2 text-gray-500"><b>${totalDetails.subtotal}</b></label>
                </div>

                {userData?.orders?.length == 0 && <div className='flex justify-beteen my-2 w-full'>
                  <label className=' w-2/3 text-red-500'><b>First Order Discount: 30%</b></label>
                  <label className=" text-end w-1/3 text-red-500"><b>${totalDetails.firstdiscount}</b></label>
                </div>}
                {userData?.orders?.length == 0 && <div className='flex justify-beteen my-2 w-full'>
                  <label className=' w-2/3 text-red-500'><b>Referred Discount: </b></label>
                  <label className=" text-end w-1/3 text-red-500"><b>$5</b></label>
                </div>}

                {/* {((totalQuantity > 9) && (totalOrders >= 9)) && <div className='flex justify-beteen my-2 w-full'>
                <label className=' w-2/3 text-red-500'><b>Buy 10th Item Discount: </b></label>
                <label className=" text-end w-1/3 text-red-500"><b> ${tenOrderdiscount}</b></label>
              </div>} */}
                {totalDetails.discount && (
                  <div className='flex justify-between my-2 w-full'>
                    <label className='w-1/2 text-red-500'><b>Discount: 15%</b></label>
                    <label className="text-end w-1/2 text-red-500"><b> ${totalDetails.discount}</b></label>
                  </div>
                )}
                {promocodedis && (
                  <div className='flex justify-between my-2 w-full'>
                    <label className='w-1/2 text-red-500'><b>Promo Discount: </b></label>
                    <label className="text-end w-1/2 text-red-500"><b> ${promocodedis}</b></label>
                  </div>
                )}


                <div className='flex  justify-beteen mt-2 w-full'>
                  <label className=' w-2/3 text-red-500'><b>Available Wallet Balance:</b></label>
                  <label className="text-end w-1/2 text-red-500"><b>${userData?.wallet || 0}</b></label>
                </div>
                <div className="wallet-amount-selector">
                  <input className="w-full  bg-orange-400 " type="range" min="0" max={userData?.wallet || 0} value={selectedAmount}
                    onChange={handleSliderChange} // Corrected onChange handler
                    onClick={(e) => setSelectedAmount(Number(e.target.value))}
                  />
                  <div className='flex  justify-beteen mb-2 w-full'>
                    <label className=' w-2/3 text-green-500'><b>Selected Wallet Amount:</b></label>
                    <label className="text-end w-1/2 text-green-500"><b>${selectedAmount}</b></label>
                  </div>

                </div>
                <label className="text-end w-1/2 text-red-500"><b>{message}</b></label>

                <div className='flex  justify-beteen my-2 w-full'>
                  <label className=' w-1/2 text-gray-500'><b>Tax:</b></label>
                  <label className="text-end w-1/2 text-gray-500"><b>${totalDetails.tax}</b></label>
                </div>

                <div className='flex  justify-beteen my-2 w-full'>
                  <label className=' w-1/2 text-gray-500'><b>Delivery:</b></label>
                  <label className=" text-end w-1/2 text-gray-500"><b>${totalDetails.deliveryCharge}</b></label>
                </div>
                <div className='flex  justify-beteen my-2 w-full'>
                  <label className=' w-1/2 text-gray-500'><b>Tip:</b></label>
                  <label className="text-end w-1/2 text-gray-500"><b>${tip}</b></label>

                </div>
                {/* <div class="inline-flex w-full items-center p-1 bg-orange-300 rounded-full shadow-orange-3 transition duration-150 ease-in-out hover:bg-orange-300 hover:shadow-orange-2 focus:bg-orange-accent-300 focus:shadow-orange-2 focus:outline-none focus:ring-0  active:shadow-orange-2 motion-reduce:transition-none dark:shadow-black/30 dark:hover:shadow-dark-1 dark:focus:shadow-dark-1 dark:active:shadow-dark-1"
                role="group">
                <button onClick={(e) => addTip(2)} class="inline-block rounded-full bg-orange-300 px-4 pb-[5px] pt-[6px] text-xs font-medium uppercase leading-normal text-white transition duration-150 ease-in-out hover:bg-orange-accent-300 focus:bg-orange-300 focus:outline-none focus:ring-0 active:bg-orange-600 motion-reduce:transition-none"
                  data-twe-ripple-init data-twe-ripple-color="light">2 $</button>
                <button onClick={(e) => addTip(4)} class="inline-block rounded bg-orange-300 px-4 pb-[5px] pt-[6px] text-xs font-medium uppercase leading-normal text-white transition duration-150 ease-in-out hover:bg-orange-accent-300 focus:bg-orange-accent-300 focus:outline-none focus:ring-0 active:bg-orange-600 motion-reduce:transition-none"
                  data-twe-ripple-init data-twe-ripple-color="light">4 $</button>
                <button onClick={(e) => addTip(5)} class="inline-block rounded bg-orange-300 px-4 pb-[5px] pt-[6px] text-xs font-medium uppercase leading-normal text-white transition duration-150 ease-in-out hover:bg-orange-accent-300 focus:bg-orange-accent-300 focus:outline-none focus:ring-0 active:bg-orange-600 motion-reduce:transition-none"
                  data-twe-ripple-init data-twe-ripple-color="light">5 $</button>
                <button onClick={handleCustomTipClick} class="inline-block rounded-full bg-orange-300 px-4 pb-[5px] pt-[6px] text-xs font-medium uppercase leading-normal text-white transition duration-150 ease-in-out hover:bg-orange-accent-300 focus:bg-orange-accent-300 focus:outline-none focus:ring-0 active:bg-orange-600 motion-reduce:transition-none"
                  data-twe-ripple-init data-twe-ripple-color="light"> {showCustomTip ? 'Add' : ' Custom Tip'}</button>
                {showCustomTip && (
                  <div className="custom-tip w-1/3">

                    <input className="w-full px-3  border-1 border-gray-200 rounded-md focus:outline-none focus:border-orange-500 transition-colors" type="number" placeholder="Enter custom tip" value={customTip} onChange={(e) => setCustomTip(e.target.value)} />
                  </div>
                )}

              </div> */}
                {/* ===================== */}

                <div className="inline-flex w-full items-center p-1 bg-[#F4F2EE] gap-x-2 rounded-full shadow transition">
                  {standardTips.map((amount) => (
                    <button key={amount} onClick={() => handleTipClick(amount)} className={`inline-block rounded-full px-3 py-1 border-[#F4F2EE] text-xs font-medium uppercase border-2 transition ${tip === amount ? 'bg-white shadow' : ' bg-[#F4F2EE]'}`}>${amount}</button>
                  ))}
                  {!showCustomTip && <button onClick={toggleCustomTip} className={`inline-block rounded-full px-4 py-1 text-xs font-medium uppercase transition ${showCustomTip || tip === parseFloat(customTip) ? 'bg-white' : ' bg-[#F4F2EE]'}`}>{showCustomTip ? 'Add' : 'Custom'}</button>}
                  {showCustomTip && (
                    <>
                      $<input type="number" placeholder="Enter custom tip" value={tip}
                        onChange={(e) => {
                          const value = e.target.value;
                          if (value === '' || Number(value) >= 0) { setTip(Number(value)) }
                        }}
                        className=" w-1/3  px-2  bg-white focus:bg-white border-2 border-gray-200 rounded-full focus:outline-none focus:border-orange-500 transition-colors" />
                    </>
                  )}
                </div>

                <div className="w-full flex items-center rounded-full my-2 bg-[#F4F2EE] relative" >
                  <input placeholder="Enter Promo Code" name="promocode" value={formData.promocode} onChange={(e) => inputPromo(e)}
                    className="w-full px-3 py-1 bg-[#F4F2EE] focus:bg-white border-2 border-[#F4F2EE] rounded-full focus:outline-none focus:border-orange-500 transition-colors" type="text" />

                  <svg onClick={() => applyPromo()} width="49" height="43" viewBox="0 0 49 43" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="0.628906" y="42.5" width="42" height="48" rx="21" transform="rotate(-90 0.628906 42.5)" fill="#F95424" />
                    <path d="M22.0674 28.5L31.5957 20.5L22.0674 12.5" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>

                  {/* <button onClick={() => applyPromo()}
                  className={`w-12 h-8 bg-orange-500 hover:bg-orange-700 focus:bg-orange-700 text-white rounded-full px-3 py-1 font-semibold `}>
                  ..
                </button> */}
                </div>
                {/* ===================== */}


                <div className='flex  border-b border-t  py-4 justify-beteen my-2 w-full'>
                  <label className=' w-1/2 text-black'><b>Total:</b></label>
                  <label className=" text-end w-1/2 text-black"><b>${totalDetails.total}</b></label>
                </div>
                <div className="my-4 flex items-center justify-beteen">
                  <input type="checkbox" id="above18" checked={isChecked} onChange={handleCheckboxChange} />
                  <label className=" px-2 font-semibold" htmlFor="above18">Are you above 21?</label>
                </div>
                <button onClick={() => paynow()} type='submit' disabled={!isChecked && totalQuantity > 0}
                  className={`block w-full max-w-xs mx-auto bg-orange-500 hover:bg-orange-700 focus:bg-orange-700 text-white rounded-full px-3 py-3 font-semibold ${isChecked ? '' : 'opacity-50 cursor-not-allowed'}`}>
                  <i className="mdi mdi-lock-outline mr-1"></i>Continue To Payment</button>
              </div>
            </div>
          </div>

          {!selectedAdress.storeAddress &&
            <div className="fixed animate-pulse flex justify- items-center bottom-[3%] left-[30%] bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded" role="alert">
              <strong className="font-bold pr-2">Sorry. </strong>
              <span className="block sm:inline"> We don't deliver in your area yet, please select a different address. </span>
              <span
                // onClick={() => { setShowSlecStore(false) }}
                className=" top-0 bottom-0 right-0 px-4 py-3">
                <svg className="fill-current h-6 w-6 text-red-500" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                  <title>Close</title>
                  <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
                </svg>
              </span>
            </div>}
        </div >
        :
        <Payment data={formData} setshowPaymentMod={setshowPaymentMod} total={totalDetails.total} payment={totalDetails} />
      }
    </div >
  );
}

const styles = {
  body: {
    fontFamily: 'Roboto, Open Sans, sans-serif',
    fontSize: '16px',
  },
  iframe: {
    height: '40px'
  },
  hydrated: {

  },
  input: {
    fontSize: '13px',
    border: '1px solid #aaa',
    height: '50px',
    borderRadius: '10px',
    padding: '8px'
  },
  img: {
    right: '10px !important',
    top: '10px !important',
  }
};
export default Checkout;

